import React, { useEffect } from 'react';
import Header from 'layouts/App/Header';
import Footer from 'layouts/App/Footer';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadCategories } from 'store/category/actions';
function Index() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadCategories());
  }, [dispatch]);
  return (
    <div className='min-h-full flex flex-col bg-gray-100'>
      <Header />
      <main className='flex flex-col flex-1'>
        <Outlet />
        <Footer />
      </main>
    </div>
  );
}

export default Index;
