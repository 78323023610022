import React from 'react';
import * as routes from 'constants/routes';
import { Route, Routes } from 'react-router-dom';
import NoMatch from 'pages/NoMatch';
import App from 'layouts/App';
import LazyPage from 'components/LazyPage';

function Router() {
  return (
    <Routes>
      <Route path={routes.HOME} element={<App />}>
        <Route index element={<LazyPage page='Index' />} />
        <Route path={routes.MAVEERAR} element={<LazyPage page='Maaveerar' />} />
        <Route
          path={routes.MAVEERAR_VIEW}
          element={<LazyPage page='Maaveerar/View' />}
        />
        <Route path={routes.RADIO} element={<LazyPage page='Radio' />} />

        <Route path={routes.TV} element={<LazyPage page='Television' />} />

        <Route path={routes.ESSAY} element={<LazyPage page='Essay' />} />
        <Route
          path={routes.ESSAY_VIEW}
          element={<LazyPage page='Essay/View' />}
        />

        <Route
          path={routes.GENOCIDES}
          element={<LazyPage page='Genocides' />}
        />
        <Route
          path={routes.GENOCIDE_VIEW}
          element={<LazyPage page='Genocides/View' />}
        />
      </Route>

      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
}

export default Router;
