// export const HOME = '/';
// export const TV = '/தொலைக்காட்சி';
// export const RADIO = '/தமிழ்நாதம்வானொலி';
// export const MAVEERAR = '/மாவீரர்கள்';
// export const MAVEERAR_VIEW = '/மாவீரர்/:slug';

// export const ESSAY = '/கட்டுரைகள்';
// export const ESSAY_VIEW = '/கட்டுரை/:slug';

// export const GENOCIDES = '/இனப்படுகொலைகள்';
// export const GENOCIDE_VIEW = '/இனப்படுகொலை/:slug';



export const HOME = '/';
export const TV = '/television';
export const RADIO = '/radio';
export const MAVEERAR = '/maveerar';
export const MAVEERAR_VIEW = '/maveerar/:slug';

export const ESSAY = '/essays';
export const ESSAY_VIEW = '/essay/:slug';

export const GENOCIDES = '/genocides';
export const GENOCIDE_VIEW = '/genocide/:slug';
