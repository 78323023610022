import moment from 'moment';
import { name } from 'config/env';

function index() {
  return (
    <div className='bg-gray-800'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex items-center justify-between h-12'>
          <span className='text-sm text-gray-300 sm:text-center '>
            © {moment().format('YYYY')}{' '}
            <span className='hover:text-white font-semibold	'>{name}</span>. All
            Rights Reserved.
          </span>
          <div className='hidden md:block'>
            <div className='ml-10 flex items-baseline space-x-4'>
              <a
                href='mailto:info@sangathamil.ca'
                className='px-3 py-2 rounded-md text-sm font-medium text-gray-300  hover:text-white'
              >
                Contact us : info@sangathamil.ca
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
