import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { NavLink, Link, useLocation } from 'react-router-dom';
import * as routes from 'constants/routes';
import Logo from 'assets/images/png/logo.png';
import { useSelector } from 'react-redux';

const navigation = [
  { name: 'செய்திகள்', link: routes.HOME },
  { name: 'இலக்கியங்கள்', link: routes.ESSAY },
  { name: 'மாவீரர்கள்', link: routes.MAVEERAR },
  { name: 'இனப்படுகொலைகள்', link: routes.GENOCIDES },
  { name: 'தொலைக்காட்சி', link: routes.TV },
  { name: 'வானொலி', link: routes.RADIO },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Index() {
  const { categories } = useSelector((state) => state.category);

  const location = useLocation();

  return (
    <Disclosure as='nav' className='bg-gray-800'>
      {({ open }) => (
        <>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex items-center justify-between h-12'>
              <div className='flex items-center'>
                <div className='flex-shrink-0'>
                  <Link to={routes.HOME}>
                    <img className='w-32' src={Logo} alt='Workflow' />
                  </Link>
                </div>
                <div className='hidden md:block'>
                  <div className='ml-10 flex items-baseline space-x-4'>
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.link}
                        className={(item) =>
                          classNames(
                            item?.isActive
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'block px-3 py-2 rounded-md text-base font-medium'
                          )
                        }
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>

              <div className='-mr-2 flex md:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='md:hidden divide-y-2 divide-gray-700'>
            <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3 '>
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as='div'
                  aria-current={item.current ? 'page' : undefined}
                >
                  <NavLink
                    to={item.link}
                    className={(item) =>
                      classNames(
                        item.isActive
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )
                    }
                  >
                    {item.name}
                  </NavLink>
                </Disclosure.Button>
              ))}
            </div>
            <div className='py-6 px-5 space-y-6'>
              <div className='grid grid-cols-2 gap-y-4 gap-x-8'>
                {categories.map((item) => {
                  return (
                    <Disclosure.Button key={item.title} as='div'>
                      <Link
                        to={`${
                          location?.pathname === '/'
                            ? routes.HOME
                            : routes.ESSAY
                        }?resource=${item?.slug}`}
                        className='flex items-center p-2 text-base font-normal text-gray-400 rounded-lg  hover:bg-gray-700 hover:text-white '
                      >
                        {item.title}
                      </Link>
                    </Disclosure.Button>
                  );
                })}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default Index;
